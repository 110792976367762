<div *ngIf="showDivider" class="owl-dt-timer-divider" aria-hidden="true"></div>
<button class="owl-dt-control-button owl-dt-control-arrow-button"
        type="button" tabindex="-1"
        [disabled]="upBtnDisabled"
        [attr.aria-label]="upBtnAriaLabel"
        (click)="upBtnClicked()">
    <span class="owl-dt-control-button-content" tabindex="-1">
        <!-- <editor-fold desc="SVG Arrow Up"> -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" x="0px" y="0px" viewBox="0 0 451.847 451.846"
                 style="enable-background:new 0 0 451.847 451.846;" xml:space="preserve"
                 width="100%" height="100%">
                    <path d="M248.292,106.406l194.281,194.29c12.365,12.359,12.365,32.391,0,44.744c-12.354,12.354-32.391,12.354-44.744,0
                        L225.923,173.529L54.018,345.44c-12.36,12.354-32.395,12.354-44.748,0c-12.359-12.354-12.359-32.391,0-44.75L203.554,106.4
                        c6.18-6.174,14.271-9.259,22.369-9.259C234.018,97.141,242.115,100.232,248.292,106.406z"/>
                </svg>
        <!-- </editor-fold> -->
    </span>
</button>
<label class="owl-dt-timer-content">
    <input class="owl-dt-timer-input" maxlength="2"
           [value]="displayValue"
           (keydown.arrowup)="!upBtnDisabled && upBtnClicked()"
           (keydown.arrowdown)="!downBtnDisabled && downBtnClicked()"
           (input)="handleInputChange(valueInput.value)"
           (focusin)="focusIn()"
           (focusout)="focusOut(valueInput.value)"
           #valueInput>
    <span class="owl-hidden-accessible">{{inputLabel}}</span>
</label>
<button class="owl-dt-control-button owl-dt-control-arrow-button"
        type="button" tabindex="-1"
        [disabled]="downBtnDisabled"
        [attr.aria-label]="downBtnAriaLabel"
        (click)="downBtnClicked()">
    <span class="owl-dt-control-button-content" tabindex="-1">
        <!-- <editor-fold desc="SVG Arrow Down"> -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" x="0px" y="0px" viewBox="0 0 451.847 451.846"
                 style="enable-background:new 0 0 451.847 451.846;" xml:space="preserve"
                 width="100%" height="100%">
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                        c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                        c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                </svg>
        <!-- </editor-fold> -->
    </span>
</button>
